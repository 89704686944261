 <template>

    <v-container >

        <v-card-title class="pt-0 pb-2"  style="font-size: 16px; line-height: 28px; border-bottom: 1px solid #e2e2e2"> Event Feed
            <v-spacer></v-spacer>
             <v-icon @click="show_config = !show_config">mdi-cog</v-icon>

        </v-card-title>


        
        <v-card-text class="pt-2 pb-0 mb-0 event-feed-content mt-1" :style="{height: `${this.height}px`}">
            <v-expand-transition>
                <v-container fluid v-show="show_config" class="mb-5 config-container">
                    <v-row dense >
                        
                        <v-col >
                            
                            <v-select
                                :items="['Trendline', 'Horizontal', 'MA']"
                                v-model="filters.type"
                                multiple
                                outlined
                                dense
                                hide-details
                                clearable
                                background-color="white"
                                >
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col >
                            <v-select
                                :items="['Support', 'Resistance']"
                                v-model="filters.side"
                                outlined
                                dense
                                hide-details
                                clearable
                                background-color="white"
                                >
                            </v-select>
                        </v-col>
                    </v-row>

                </v-container>
            </v-expand-transition>

            <template v-for="e in filtered_events">
                <event :key="e.id" :event="e"></event>
              
            </template>

            <v-row v-if="!events.length">
                <v-col class="pa-8 text-centertext-subtitle-2">No Events Found</v-col>
            </v-row>
        </v-card-text>


    <!-- <v-tabs-items v-model="view">
      <v-tab-item>
        <v-card flat>
            <v-card-text>
                <template v-for="e in events">
                     <event :key="e.id" :event="e"></event>
                    <v-divider :key="e.id + '_divider'"></v-divider>
                </template>

                <v-row v-if="!events.length">
                    <v-col class="pa-8 text-centertext-subtitle-2">No Events Found</v-col>
                </v-row>
            </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
            <v-card-text>
                <template v-for="a in triggeredAlerts">
                     <alert :key="a.id" :alert="a"></alert>
                    <v-divider :key="a.id + '_divider'"></v-divider>
                </template>

                <v-row v-if="!alerts.length">
                    <v-col class="pa-8 text-centertext-subtitle-2">No Alerts Found</v-col>
                </v-row>
            </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items> -->


    <div >
       
        </div> 
    </v-container>
</template>
     
 <script>
    import { mapGetters, mapMutations } from 'vuex';
      import Alert from '../base/Alert.vue';
      import Event from './Event.vue';
    export default {
        name: 'App',
        data: () => ({
            view: 'events',
            height: 500,
            show_config: false,
            filters: {
                side: null,
                type: []
            }
        }),
        components: {
            Alert,
            Event,
        },
        mounted() {
          window.addEventListener("resize", this.onWindowResize);
          this.onWindowResize();
 
          
        },
        created(){
      
        },
        unmounted() {
            window.removeEventListener("resize", this.onWindowResize);
        },
        computed: {
            ...mapGetters({
                alerts: 'notificationsStore/getAlerts',
                triggeredAlerts: 'notificationsStore/getTriggeredAlerts', 
                events: 'notificationsStore/getEvents',
                
            }),
            filtered_events(){

                return this.events.filter(e => {
                    
                    if(this.filters.side && this.filters.side.toLowerCase() !== e.side) return false;
                    console.log("this.filters.type", this.filters.type)
                    if(!this.filters.type.length) return true;

                    let filter_types = this.filters.type.map(t => t.toLowerCase()); 
                    console.log("filter_types", filter_types)
                    if(filter_types.length && filter_types.indexOf(e.type) < 0) return false;
                    return true;

                })
            }
        },
        methods: {
            onWindowResize(e){
                this.height = window.innerHeight - 64 - 70 - 65
            }
        }
        
    }
</script>

<style>

    .event-feed-content{
        overflow: scroll;
    }
    .config-container{
        background-color: #f5f5f5;
        border: 1px solid #e2e2e2;
    }
</style>
