<template>
 

    <div class="search-bar-container">
        <!-- <div class="d-flex" style="flex:1"></div> -->
    
        <div class="d-flex search-bar" style="flex: 0 1;">

            <div style="width: 125px" class="mr-2">
                <v-autocomplete
                    solo
                    flat
                    background-color="white"
                    hide-details
                    v-model="model"
                    :items="items"
                    :loading="isLoading"
                    :search-input.sync="search"
                    item-text="ticker"
                    item-value="ticker"
                    placeholder="Search"
                    return-object
                    @keyup.enter="onEnter"
                ></v-autocomplete>

            </div>

            <div style="width: 175px">
                <v-menu ref="menu" v-model="date_picker_menu" :close-on-content-click="false" transition="scale-transition" offset-y left  min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                            :value="date_picker_menu_date" 
                            solo
                            flat
                            background-color="white"
                            hide-details 
                            prepend-inner-icon="mdi-calendar" 
                            readonly 
                            v-bind="attrs" 
                            clearable
                            @click:clear="onClear"
                            v-on="on">
                        </v-text-field>
                    </template>
                    <v-date-picker :max="max_date" v-model="date"  scrollable >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="date_picker_menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="saveDate">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </div>
            <div>
                <v-btn  depressed @click="nextDay" color="white" height="48" class="px-0 ml-3 unset-case text--darken-3 neutral--text"><v-icon>mdi-chevron-right</v-icon></v-btn>
            </div>
            <div>
                <v-btn depressed @click="reload" color="white" height="48" class="px-0 ml-3 unset-case text--darken-3 neutral--text"><v-icon>mdi-reload</v-icon></v-btn>
            </div>

            <v-btn-toggle 
               
            v-model="active_viewports" 
            multiple
            background-color="#e2e2e2"
            
            color="accent"
            group
            class="ml-2 mt-0 pt-0"
            
            >
            <v-btn  height="48" class="ma-0 unset-case " value="scan">Scan</v-btn>
            <v-btn  height="48" class="ma-0 unset-case " value="notifications">Notifications</v-btn>
            <v-btn  height="48"  class="ma-0 unset-case"  value="stock">Stock</v-btn>
            <v-btn  height="48" class="ma-0 unset-case" value="spy">Spy</v-btn>
          </v-btn-toggle>


        </div>

         
<!--     
        <div class="d-flex" style="flex:1">
            
            <v-btn depressed color="neutral" class="ml-3 unset-case error--text" @click="clearAllTabs"><v-icon>mdi-close</v-icon></v-btn>
        </div> -->

    </div>
</template>

<script>
    const { DateTime } = require("luxon");
    import { mapGetters, mapMutations } from 'vuex';
  import { EventBus } from '../../modules/EventBus.js';
     export default {
        name: 'SearchBar',
        data: () => ({
            date_picker_menu: '',
            date_picker_menu_date: '',
            date: '', 
            max_date: '',
            ticker_id: '',
            model: null,
            selectedTicker: '',
            ticker: '',
            search: null,
            entries: [],
            isLoading: false

        }),
        computed: {
            ...mapGetters({
                isLoaded: 'dashboardStore/isLoaded',
                activeTicker: 'dashboardStore/getActiveTicker',
                tickerList: 'dashboardStore/getTickerList',
                chart_date: 'dashboardStore/getChartDate',
                viewports: 'baseStore/getViewports',
            }), 
            ...mapMutations({
                setActiveTicker: 'dashboardStore/setActiveTicker',
                  setViewports: 'baseStore/setViewports'
            }), 
             active_viewports: {
                /* By default get() is used */
                get() {
                    return this.viewports
                },
                /* We add a setter */
                set(value) {
                    this.$store.commit( 'baseStore/setViewports', value);
                }
            },
            items () {
                return this.entries || [];
            }
        },
        async created(){
           const options = {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
            };
             var d = new Date();    
            d = new Date(d.getTime() - d.getTimezoneOffset() * 60000)
            
            this.max_date = new Date().toISOString().split('T')[0]


            
            if(this.$route.params.date){
                this.date = this.$route.params.date
            } else {
               this.date = d.toISOString().split('T')[0]
            }
            this.saveDate();

            console.log("this.$route.params.symbol", this.$route.params.symbol)
            if(this.$route.params.symbol){    
                this.model = {ticker: this.$route.params.symbol}
                // this.$store.commit('dashboardStore/setActiveTicker', {
                //     ticker: this.$route.params.symbol,
                //     set_active: true
                // }); 
                
            }   


            this.$store.dispatch('dashboardStore/setTicker',  {
                ticker: 'SPY',
            });

            console.log(this.date);
        },
         methods:{

             nextDay(){
                let date = new DateTime.fromISO(this.chart_date).setZone('America/New_York');

                let newDate = {};
                if(date.weekday >= 5){
                    newDate =  date.plus({'day': (7 - date.weekday + 1) % 7}).toISODate()
                } else {
                    newDate =  date.plus({'day': 1}).toISODate()
                }

                this.$store.dispatch('dashboardStore/setDate', {
                    date: newDate
                });


                // if(((7 - date.weekday + 1) % 7)
            }, 
           onEnter(e){
               if(this.search){
                   this.model = {
                       ticker: this.search
                   };
               }
           }, 
            onClear(val){
                let date = new DateTime.now();
                this.$store.dispatch('dashboardStore/setDate', {
                    date: date.toISODate()
                });
            
           }, 
           reload(){
                this.$store.dispatch('dashboardStore/setTicker',  {
                    ticker: this.activeTicker,
                    set_active: true
                });
                this.$store.dispatch('dashboardStore/setTicker',  {
                    ticker: 'SPY',
                });
                EventBus.$emit('reload_stock');
            },
            close(){
                this.$store.dispatch('dashboardStore/setTicker',  {
                    ticker: null,
                    set_active: true
                });
            }, 


            async getTickers(){  
                let results = await this.api.get(`/tickers/search`, {
                    search: this.search
                });
                this.entries = results.tickers || []
                this.isLoading = false
            }, 

            async saveDate(){
                await this.$store.dispatch('dashboardStore/setDate', {
                    date: this.date
                });
                this.date_picker_menu = false;
            },
            clearAllTabs(){
                this.$store.commit('dashboardStore/clearAllTabs'); 
            }, 
         },
         watch: {
           chart_date(val){
               this.date_picker_menu_date = val
               this.date = val;

               this.$store.dispatch('dashboardStore/setTicker', {
                    ticker: 'SPY'
                });

           }, 
           
            activeTicker(val){
                let selectedIndex = this.tickerList.findIndex(t =>  t === val);
                this.selectedTicker = selectedIndex;
            }, 
            selectedTicker(selectedTicker){
                this.$store.commit('dashboardStore/setActiveTicker', {
                    ticker: this.tickerList[selectedTicker]
                }); 
            },
            
            async model(val){
                if(!val) return;
              
                await this.$store.dispatch('dashboardStore/setTicker', {
                    ticker: this.model.ticker,
                    set_active: true
                });
                let selectedIndex = this.tickerList.findIndex(t =>  t === this.model.ticker);
                this.selectedTicker = selectedIndex;
                this.model = null
            }, 
            search (val) {
        
                // Items have already been loaded
                // if (this.items.length > 0) return
        
                // Items have already been requested
                this.underlying = val;
                // if (this.isLoading) return
                // this.isLoading = true
                this.getTickers();
                
            },
            active_viewports(){
                this.$nextTick(() => {
                  EventBus.$emit('resize');
                })
            },
        }
     }
</script>
<style scoped>

.search-bar-container{
    display: flex; width: 100%; border-bottom: 1px solid #e2e2e2;  justify-content: center; background-color: #eef2f3;
}
</style>
