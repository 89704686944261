<template>
    
    <v-row :key="e.id"  @click="viewTicker(true)" class="pt-1"  >
        
        <!-- <v-col cols="2" class="text-center pt-3">
            <span class="notifcation-icon">
                <v-avatar  size="24" color="warning">
                    <v-icon small dark >mdi-alert-circle</v-icon>
                </v-avatar>
            </span> 
        </v-col> --> 
        <v-col class="py-2 event" :class="e.is_new ? 'is-new': ''" style="" >
             <div class="text-subtitle-2">
                 <span class="primary--text"><strong>{{e.Ticker.symbol}}</strong></span> {{e.text}} 
            </div>
            <span class="subdued--text">{{e.time | localDateTime}}</span>
        </v-col>
        
    </v-row>
</template>
<script>
    import { EventBus } from '../../modules/EventBus.js';
    const { DateTime } = require("luxon");
    export default {
        name: 'App',
        data: () => ({
            e: {}
        }),
        props: ['event'],
        computed: {},
        filters:{
            
        }, 
        created(){
            this.e = JSON.parse(JSON.stringify(this.event));
            if(this.e.is_new){
                setTimeout(() => {
                    this.e.is_new = false;
                }, 1000 * 60 * 5); 
            }
        }, 
        filters:{
            friendlyDate(time){
                if(!time) return '';
                return DateTime.fromFormat(time, 'yyyy-MM-dd HH:mm:ss', { zone: "America/New_York" }).toLocal().toRelative({unit: 'minutes'})
                
            },
            localDateTime(time){
                 if(!time) return '';
                return DateTime.fromFormat(time, 'yyyy-MM-dd HH:mm:ss', { zone: "America/New_York" }).toLocal().toFormat('MM/dd hh:mm a')
            },
            // makeText(e){
            //     let text = '';
            //     switch(e.type){
            //         case 'trendline':
            //             text = ` is near trendline ${e.side} at $${Math.round(e.price * 1e2) / 1e2}.`
            //             break;
            //         case 'horizontal':
            //             text = ` is at horizontal ${e.side} at $${Math.round(e.price * 1e2) / 1e2}.`
            //             break;
            //         case 'ma':
            //             text = ` is at ${e.threshold_id} ma ${e.side} at $${Math.round(e.price * 1e2) / 1e2}.`
            //             break;

            //     }
            //     return text;
            // }
        },
        methods: {
            viewTicker(){
                this.$store.dispatch('dashboardStore/setTicker',  {
                    ticker: this.e.Ticker.symbol,
                    set_active: true
                });
            }
        }
    }
</script>

<style scoped>
    .event{
        border: 1px solid #e2e2e2; background-color: #f5f5f5;
    }
    .event.is-new{
        border: 1px solid #2ECC70; background-color: rgba(46,204,112, .2);
    }
    .read{ 
        opacity: .50;
        background-color: #f5f5f5;
    }
    .notifcation-icon {
        padding-top: 15px;

    }
    .triggered-time{
        margin-top: 5px;
        opacity: .66 
    }
</style>

