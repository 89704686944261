<template>
    <div class="site-structure">

        <SearchBar></SearchBar>
    
        <div class="app-flex" style="flex: 1">
            
            <div  v-if="showViewport('scan')" class="left-panel" style="flex 0 1 400px"><Scanner /></div>
            <div v-if="showViewport('stock')" class="center-panel px-2"><dashboard-panel role="live"></dashboard-panel></div>
            <div v-if="showViewport('spy')" class="right-panel"><dashboard-panel id="spy" ticker="SPY" role="live"></dashboard-panel></div>

            <div style="flex: 0 0 360px; border-left: 1px solid #e2e2e2" v-if="showViewport('notifications')" class="event-feed-panel" >                
                <ControlPanel />
            </div>
            
        </div>
    </div>

</template>
<script>
    import DashboardPanel from "../dashboard/DashboardPanel"
    // import Dashboard from "../dashboard/Index"
    import Scanner from "../scanner/List"
    import ControlPanel from "../notifications/ControlPanel"
    import { mapGetters, mapMutations } from 'vuex';
    import { EventBus } from '../../modules/EventBus';
    import SearchBar from "./SearchBar"
export default {
    
     data: () => ({   
        //  isResizing: false,
        //  expandablePanel: {}, 
        //  container: {} 
      }),
      created(){
        if(this.$route.query.view){
            this.$store.commit( 'baseStore/setViewports', this.$route.query.view);
        }
        
        
        //   this.expandablePanel = this.$refs.rightPanel;
        // this.container = this.$refs.panelHolder;
          
        // document.onmousemove = this.movePanel;
        // document.onmouseup = this.stopResizing;
    
      }, 

      computed:{
        ...mapGetters({
            tickerList: 'dashboardStore/getTickerList',
            viewports: 'baseStore/getViewports'
        }),
        ...mapMutations({
            setActiveTicker: 'dashboardStore/setActiveTicker'
        }), 
      }, 
    components:{
        DashboardPanel,
        Scanner,
        ControlPanel,
        SearchBar
    },
    methods: {
        showViewport(panel){
            return this.viewports.indexOf(panel) >= 0


        },
        // setIsResizing(){
        //     this.isResizing = true;
        //     EventBus.$emit('isResizing', true); 
        // },
        // movePanel(e){
        //     if (!this.isResizing) return;
        //     var offsetRight = this.container.clientWidth - (e.clientX - this.container.offsetLeft);
        //     this.$refs.rightPanel.style.flexBasis = offsetRight + "px";
        // },
        // stopResizing(){
        //     if(this.isResizing){
        //         this.isResizing = false;
        //         EventBus.$emit('isResizing', false); 
        //         EventBus.$emit('reflow');     
        //     }
        // }
    },
    
}
</script>

<style scoped>
    .panel-holder{
        width: 100%;
        align-items: stretch;
    }
    
    .left-panel,
    .center-panel,
    .right-panel{
        flex: 1 0;
        overflow: hidden; 
    }
    .event-feed-panel{
        flex: 1 0;
        overflow: hidden; 
    }
     .left-panel{
           flex: 0 1 720px;
     }
    .drag-handle{
        display: flex;
        flex: 0 1 18px;
        border-left: 1px solid #e2e2e2;
        border-right: 1px solid #e2e2e2;
        text-align: center;
        align-items: center;
        justify-content: center;

    }
    .drag-handle .drag-icon{
        cursor: grab;
    }

    .app-flex{
        display: flex;
        align-items: stretch;
    }
    
</style>